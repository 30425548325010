<template>
  <div>
    <van-nav-bar title="拓客申请记录" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <!-- <div id="qrcode"></div> -->
    <div style="padding-top:46px;">
      <div v-for="(item,index) in list" :key="index">
        <van-row>
          <van-col span="12" class="ls">{{item.garageName}}</van-col>
          <van-col span="12" class="rs">
            <div class="dd" style="color: #ee0a24;" v-if="item.status == 0">待审核</div>
            <div class="dd" style="color: #4fc08d" @click="toSh(item)" v-if="item.status == 1">审核通过</div>
            <div class="dd" style="color: #ee0a24" v-if="item.status == 2">审核未通过</div>
            <!-- <div
              :id="`qrcode${index}`"
              class="qrcode"
              :ref="`qrCodeUrl${index}`"
              @click="clickImg(item)"
              v-if="item.status == 1"
            ></div> -->
          </van-col>
        </van-row>
        <van-row>
          <van-col span="12" class="ls">提交时间</van-col>
          <van-col span="12" class="rs">{{item.createdDate}}</van-col>
        </van-row>
        <van-row>
          <van-col span="12" class="ls">审核时间</van-col>
          <van-col span="12" class="rs">{{item.lastModifiedDate}}</van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import userwei from "../../api/user";
import QRCode from "qrcodejs2";
export default {
  components: {},
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      
      list: [],
      value: ""
    };
  },
  methods: {
    onLoad() {
      let _this = this;
      // this.userInfo.id 
      userwei
        .applyList({ customerId: this.userInfo.id })
        .then(res => {
          // loading.clear();
          this.list = res.data;

          console.log(this.list);
          // this.$nextTick(() => {
          //   this.list.forEach((item, index) => {
          //      if(item.status == 1){
          //         var qrcode = new QRCode("qrcode" + [index], {
          //       text: "http://localhost:8080/tuoke_initiation", // 需要转换为二维码的内容
          //       width: 30,
          //       height: 30,
          //       colorDark: "#000000",
          //       colorLight: "#ffffff",
          //       correctLevel: QRCode.CorrectLevel.H
          //     });
          //     }
          //   });
          // });

          // },
        })
        .catch //() =>
        //  loading.clear()
        ();
    },

    clickImg(e) {
      // console.log(e)
      this.$router.push({
        name: "tuoke_ma",
        params: {
          gid: e.gid,
          customerId: e.customerId,
          garageName: e.garageName
        }
      });
    },
    toSh(e) {
      console.log(e);
      this.$router.push({
        name: "tuoke_result",
        params: {
          gid: e.gid,
          tokerId: e.id,
          name: e.garageName
        }
      });
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.creatQrCode();
    // });
    // this.creatQrCode();
  }
};
</script>

<style lang="less" scoped>
div {
  color: #323233;
}
.views {
  /deep/ div:nth-of-type(2) span {
    color: #1989fa;
  }
}
.sumit {
  width: 100%;
  padding: 10px;
  position: absolute;
  bottom: 0;
}
.van-button {
  width: 50%;
}
.van-row {
  font-size: 13px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #ebedf0;
  overflow: hidden;
}
.ls {
  padding-left: 20px;
}
.rs {
  height: 50px;
  overflow: hidden;
  text-align: right;
  padding-right: 20px;
}
.dd {
  // display: inline-block;
  float: right;
}
.qrcode {
  // display: inline-block;
  float: right;
  margin-top: 10px;
  margin-right: 25px;
  // img {
  //     width: 132px;
  //     height: 132px;
  //     background-color: #fff; //设置白色背景色
  //     padding: 6px; // 利用padding的特性，挤出白边
  //     box-sizing: border-box;
  // }
}
</style>
